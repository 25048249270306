<template>
  <!-- MODAL CREAR Y EDITAR -->
  <form-modal v-bind="bindChain.form" v-on="onChain.form">
    <v-form :ref="formRef">
      <div class="d-flex">
        <v-autocomplete
          :items="behaviour"
          v-model="form.comportamiento"
          label="Comportamiento"
          placeholder="Seleccione un comportamiento"
          item-value="codigo"
          item-text="titulo"
          @change="handlerBehaviorSelected"
          width="400"
          :rules="[(v) => !!v || 'Debe seleccionar un comportamiento.']"
        >
          <template v-slot:item="{ item }">
            {{ item.codigo }} - {{ item.titulo }}
          </template>
        </v-autocomplete>
        <v-divider vertical inset class="ml-3 mr-2"></v-divider>
        <v-tooltip top v-if="selectedBehaviour" color="primary" max-width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon large v-on="on" v-bind="attrs" color="primary">{{
              icons.info
            }}</v-icon>
          </template>
          <p>
            <b>Comportamiento {{ selectedBehaviour.codigo || "" }}:</b>
            <br />
            "{{ selectedBehaviour.titulo || "" }}" <br />
            {{ selectedBehaviour.descripcion || "" }}
          </p>
        </v-tooltip>
        <v-icon large v-else>{{ icons.info }}</v-icon>
      </div>

      <v-text-field
        v-model="form.poliza_nombre"
        label="Nombre para el tipo de póliza"
        placeholder="Escriba un nombre para el tipo de póliza"
        :rules="[(v) => !!v || 'El campo nombre es requerido.']"
      ></v-text-field>
      <v-textarea
        v-model="form.poliza_descripcion"
        label="Descripción del tipo de póliza"
        placeholder="Redacte una descripción para el tipo de póliza"
        :rows="4"
        textarea
        :rules="[(v) => !!v || 'El campo es descripción querido.']"
      ></v-textarea>
      <v-text-field
        v-model="form.codigo"
        label="Código"
        placeholder="Escriba un código para el tipo de póliza"
        :rules="[(v) => !!v || 'El campo es código querido.']"
      ></v-text-field>
    </v-form>
  </form-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormMixin from "@/Mixins/SetFormMixin.js";
export default {
  name: "TipoPolizaCrudForm",
  mixins: [FormMixin],
  data: () => ({
    defaultTitle: "Formulario tipo póliza",
    form: {
      poliza_nombre: "",
      poliza_descripcion: "",
      codigo: "",
      comportamiento: "",
    },
    selectedBehaviour: null,
    loadingBehaviour: false,
  }),
  computed: {
    ...mapGetters("NewProduct", ["behaviour"]),
  },
  methods: {
    ...mapActions("NewProduct", ["REQUEST_BEHAVIOUR"]),
    setup() {
      this.loadingBehaviour = true;
      this.REQUEST_BEHAVIOUR()
        .catch((message) => this.snackbar(message, "error"))
        .finally(() => (this.loadingBehaviour = false));
    },
    handlerBehaviorSelected(e) {
      const itemChecked = this.behaviour.find(
        (element) => element.codigo === e
      );
      if (itemChecked) {
        this.selectedBehaviour = itemChecked;

        this.form.poliza_nombre = itemChecked.titulo;
        this.form.poliza_descripcion = itemChecked.descripcion;
      }
    },
  },
};
</script>
