<template>
  <v-container>
    <v-data-table v-bind="bind.table" :search="search">
      <template v-slot:top>
        <table-top v-bind="bindChain.tableTop" v-on="onChain.tableTop" />
      </template>

      <template #item.eliminado="{ item }">
        <v-chip
          class="ma-2"
          small
          :color="item.eliminado ? 'grey lighten-2' : 'primary'"
        >
          <v-icon x-small class="mr-1">mdi-checkbox-blank-circle</v-icon>
          {{ item.eliminado ? labels.disabled : labels.enabled }}
        </v-chip>
      </template>

      <template #item.actions="{ item }">
        <table-actions
          v-bind="bindChain.tableActions"
          v-on="onChain.tableActions(item)"
          :deleteDisabled="item[itemDisabled]"
        >
          <v-tooltip top color="success">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                @click="activateItem(item)"
                v-on="on"
                v-bind="{ ...attrs, ...bind.actions }"
                :disabled="!item.eliminado"
              >
                <v-icon> {{ icons.activate }} </v-icon>
              </v-btn>
            </template>
            <span>Activar</span>
          </v-tooltip>
        </table-actions>
      </template>
    </v-data-table>
    <Form v-model="dialog" v-bind="bind.form" v-on="on.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageMixin from "@/Mixins/SetPageMixin.js";
import Form from "@/components/Forms/cruds/tipoPoliza.vue";
export default {
  name: "PolicyTypeMaintainer",
  mixins: [PageMixin],
  components: {
    Form,
  },
  data: () => ({
    itemId: "tipo_poliza",
    itemName: "poliza_nombre",
  }),
  computed: {
    ...mapGetters("Polizas", ["policyTypes"]),
    items() {
      return this.policyTypes;
    },
    headers() {
      return [
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: this.keys.description,
        },
        {
          text: "Código",
          align: "start",
          sortable: true,
          value: this.keys.code,
        },
        {
          text: "Comportamiento",
          align: "center",
          sortable: true,
          value: this.keys.behaviour,
        },
        {
          text: "Estado",
          align: "center",
          sortable: true,
          value: this.keys.deleted,
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ];
    },
    keys() {
      return {
        id: "tipo_poliza",
        description: "poliza_nombre",
        deleted: "eliminado",
        behaviour: "comportamiento",
        code: "codigo",
      };
    },
  },
  methods: {
    ...mapActions("Polizas", [
      "REQUEST_POLICY_TYPE",
      "CreatePolicyType",
      "UpdatePolicyType",
      "DeletePolicyType",
      "ActivatePolicyType",
    ]),

    setup() {
      this.loading = true;
      this.REQUEST_POLICY_TYPE()
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveMethod() {
      return this.CreatePolicyType;
    },
    updateMethod() {
      return this.UpdatePolicyType;
    },
    activateMethod() {
      return this.ActivatePolicyType;
    },
    deleteMethod() {
      return this.DeletePolicyType;
    },
  },
};
</script>
